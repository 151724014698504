import { MenuOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Drawer, Layout, Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { IconCloseModalPolicy, LogoMenu } from "src/assets/icons";
import {
  hasStorageJwtToken,
  removeStorageJwtToken,
  removeStorageRefreshToken,
} from "src/helpers/storage";
import useWindowSize from "src/hook/useWindowSize";
import {
  MENUS_ITEM,
  MENUS_KEY,
  SUB_MENU_MONITORING,
} from "../../constants/sidebar";
import "./styles.scss";
import { AuthServices } from "src/services/auth-service";
import { useSelector } from "react-redux";
import { PATHS } from "src/constants/paths";
import { isEmpty } from "lodash";

const { Header, Content, Sider } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum UserType {
  USER = "user",
  ADMIN = "admin",
  ENGINEER = "engineer",
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const role = useSelector((state: any) => state.currentAccount);
  const adminRole = role === UserType.ADMIN;
  const engineerRole = role === UserType.ENGINEER;

  const { isMobileAndTablet } = useWindowSize();
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const accept = !!sessionStorage.getItem("accept");
  const [showAcceptCookies, setShowAcceptCookies] = useState<boolean>(
    accept ? !accept : true
  );
  const [dataMonitoring, setDataMonitoring] = useState<any>([]);

  const fetchListZone = async () => {
    try {
      const service = new AuthServices();
      const res = await service.getZones({ page: 1, limit: 10 });
      if (res.data.data.data) {
        setDataMonitoring(res.data.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (hasStorageJwtToken()) {
      fetchListZone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const goToThePage = (page: string) => {
    history.push(`/${page}`);
  };

  const SIDEBAR_MENUS_USER: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.OVERVIEW.icon,
      label: MENUS_ITEM.OVERVIEW.label,
      key: MENUS_ITEM.OVERVIEW.key,
      onClick: () => goToThePage(MENUS_KEY.OVERVIEW),
    },
    {
      icon: MENUS_ITEM.MONITORING.icon,
      label: MENUS_ITEM.MONITORING.label,
      key: MENUS_ITEM.MONITORING.key,
      children: dataMonitoring.map((item: any) => {
        if (!isEmpty(item)) {
          return {
            // icon: MENUS_ITEM.MONITORING.icon,
            label: item?.description,
            key: SUB_MENU_MONITORING.find(
              (submenu: any) => submenu.label === item.zone
            )?.path,
            onClick: () =>
              goToThePage(
                `${
                  SUB_MENU_MONITORING.find(
                    (submenu: any) => submenu.label === item.zone
                  )?.path
                }/${item.id}`
              ),
          };
        }
        return {};
      }),
    },
    {
      icon: MENUS_ITEM.LOGOUT.icon,
      label: MENUS_ITEM.LOGOUT.label,
      key: MENUS_ITEM.LOGOUT.key,
      onClick: () => {
        removeStorageJwtToken();
        removeStorageRefreshToken();
        history.push("/login");
      },
    },
  ];

  const SIDEBAR_MENUS_ENGINEER: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.OVERVIEW.icon,
      label: MENUS_ITEM.OVERVIEW.label,
      key: MENUS_ITEM.OVERVIEW.key,
      onClick: () => goToThePage(MENUS_KEY.OVERVIEW),
    },
    {
      icon: MENUS_ITEM.MONITORING.icon,
      label: MENUS_ITEM.MONITORING.label,
      key: MENUS_ITEM.MONITORING.key,
      children: dataMonitoring.map((item: any) => {
        if (!isEmpty(item)) {
          return {
            // icon: MENUS_ITEM.MONITORING.icon,
            label: item?.description,
            key: SUB_MENU_MONITORING.find(
              (submenu: any) => submenu.label === item.zone
            )?.path,
            onClick: () =>
              goToThePage(
                `${
                  SUB_MENU_MONITORING.find(
                    (submenu: any) => submenu.label === item.zone
                  )?.path
                }/${item.id}`
              ),
          };
        }
        return {};
      }),
    },
    {
      icon: MENUS_ITEM.DEVICE_MANAGEMENT.icon,
      label: MENUS_ITEM.DEVICE_MANAGEMENT.label,
      key: MENUS_ITEM.DEVICE_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.DEVICE_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.ZONE_MANAGEMENT.icon,
      label: MENUS_ITEM.ZONE_MANAGEMENT.label,
      key: MENUS_ITEM.ZONE_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.ZONE_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.ALARM.icon,
      label: MENUS_ITEM.ALARM.label,
      key: MENUS_ITEM.ALARM.key,
      onClick: () => goToThePage(MENUS_KEY.ALARM),
    },
    {
      icon: MENUS_ITEM.DOWNLOAD.icon,
      label: MENUS_ITEM.DOWNLOAD.label,
      key: MENUS_ITEM.DOWNLOAD.key,
      onClick: () => goToThePage(MENUS_KEY.DOWNLOAD),
    },
    {
      icon: MENUS_ITEM.NOTIFICATION_SETTING.icon,
      label: MENUS_ITEM.NOTIFICATION_SETTING.label,
      key: MENUS_ITEM.NOTIFICATION_SETTING.key,
      onClick: () => goToThePage(MENUS_KEY.NOTIFICATION_SETTING),
    },
    {
      icon: MENUS_ITEM.LOGOUT.icon,
      label: MENUS_ITEM.LOGOUT.label,
      key: MENUS_ITEM.LOGOUT.key,
      onClick: () => {
        removeStorageJwtToken();
        removeStorageRefreshToken();
        history.push("/login");
      },
    },
  ];

  const SIDEBAR_MENUS_ADMIN: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.OVERVIEW.icon,
      label: MENUS_ITEM.OVERVIEW.label,
      key: MENUS_ITEM.OVERVIEW.key,
      onClick: () => goToThePage(MENUS_KEY.OVERVIEW),
    },
    {
      icon: MENUS_ITEM.MONITORING.icon,
      label: MENUS_ITEM.MONITORING.label,
      key: MENUS_ITEM.MONITORING.key,
      children: dataMonitoring.map((item: any) => {
        if (!isEmpty(item)) {
          return {
            // icon: MENUS_ITEM.MONITORING.icon,
            label: item?.description,
            key: SUB_MENU_MONITORING.find(
              (submenu: any) => submenu.label === item.zone
            )?.path,
            onClick: () =>
              goToThePage(
                `${
                  SUB_MENU_MONITORING.find(
                    (submenu: any) => submenu.label === item.zone
                  )?.path
                }/${item.id}`
              ),
          };
        }
        return {};
      }),
    },
    {
      icon: MENUS_ITEM.DEVICE_MANAGEMENT.icon,
      label: MENUS_ITEM.DEVICE_MANAGEMENT.label,
      key: MENUS_ITEM.DEVICE_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.DEVICE_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.ZONE_MANAGEMENT.icon,
      label: MENUS_ITEM.ZONE_MANAGEMENT.label,
      key: MENUS_ITEM.ZONE_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.ZONE_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.ALARM.icon,
      label: MENUS_ITEM.ALARM.label,
      key: MENUS_ITEM.ALARM.key,
      onClick: () => goToThePage(MENUS_KEY.ALARM),
    },
    {
      icon: MENUS_ITEM.DOWNLOAD.icon,
      label: MENUS_ITEM.DOWNLOAD.label,
      key: MENUS_ITEM.DOWNLOAD.key,
      onClick: () => goToThePage(MENUS_KEY.DOWNLOAD),
    },
    {
      icon: MENUS_ITEM.LOGOUT.icon,
      label: MENUS_ITEM.LOGOUT.label,
      key: MENUS_ITEM.LOGOUT.key,
      onClick: () => {
        removeStorageJwtToken();
        removeStorageRefreshToken();
        history.push("/login");
      },
    },
  ];

  const handleOpenMobileMenu = () => {
    setIsMenuMobileOpen(true);
  };

  const getMenus = () => {
    if (engineerRole) {
      return SIDEBAR_MENUS_ENGINEER;
    } else if (adminRole) {
      return SIDEBAR_MENUS_ADMIN;
    } else {
      return SIDEBAR_MENUS_USER;
    }
  };

  return (
    <>
      {hasStorageJwtToken() && location.pathname.includes(PATHS.policy()) ? (
        <>
          {children}
          {showAcceptCookies && (
            <div className="modal-cookie">
              <div className="block-close">
                <img
                  onClick={() => setShowAcceptCookies(false)}
                  src={IconCloseModalPolicy}
                  className="close-icon"
                  alt="close-icon"
                  width={12}
                />
              </div>
              <div className="content-modal">
                เราใช้อีเมลเพื่อส่งการแจ้งเตือนของค่าอุณหภูมิและความชื้นที่ต่ำและสูงกว่ากำหนดเท่านั้น
              </div>
              <br />
              <div className="link-policy">
                <a href={PATHS.policy()}>
                  อ่านเพิ่มเติมคลิก (Privacy Policy) และ (Cookie Policy)
                </a>
              </div>
              <div
                className="button-accept"
                onClick={() => {
                  setShowAcceptCookies(false);
                  sessionStorage.setItem("accept", "true");
                  document.cookie = "accepted=true; path=/";
                }}
              >
                รับทราบ
              </div>
            </div>
          )}
        </>
      ) : hasStorageJwtToken() ? (
        <div id="layout">
          <Layout>
            <Header className="header">
              {isMobileAndTablet ? (
                <div className="header__mobile-header">
                  <Button
                    className="header__mobile-header__menu-btn"
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={handleOpenMobileMenu}
                  />
                </div>
              ) : (
                <>
                  <div className="logo">
                    <img src={LogoMenu} alt="login-background" />
                  </div>
                  <div className="other">
                    <div>Temperature/Humidity Sensor Monitoring</div>
                    <div>
                    คลังยาองค์การเภสัชกรรมสาขาภาคเหนือ จ.เชียงใหม่
                    </div>
                  </div>
                  <div className="user_name">{localStorage.getItem("username")}</div>
                </>
              )}
            </Header>
            <Layout>
              {isMobileAndTablet ? (
                <>
                  <Drawer
                    placement={"left"}
                    width={335}
                    onClose={() => setIsMenuMobileOpen(false)}
                    open={isMenuMobileOpen}
                    className="menu-mobile"
                    zIndex={1001}
                  >
                    <Menu
                      mode="inline"
                      defaultSelectedKeys={[MENUS_KEY.DASHBOARD]}
                      defaultOpenKeys={[]}
                      items={getMenus()}
                      selectedKeys={[path]}
                      inlineCollapsed={false}
                    />
                  </Drawer>
                </>
              ) : (
                <Sider width={200} className="site-layout-background">
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[MENUS_KEY.DASHBOARD]}
                    defaultOpenKeys={[]}
                    style={{ borderRight: 0 }}
                    items={getMenus()}
                    selectedKeys={[path]}
                  />
                </Sider>
              )}
              <Layout style={{ padding: "0 24px 24px" }}>
                <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
                <Content className="content">{children}</Content>
                <div className="footer">
                  <div className="text-over">© Copyright 2023 GPO</div>
                  <div className="text-over">Powered by PSEC</div>
                </div>
              </Layout>
            </Layout>
          </Layout>
          {showAcceptCookies && (
            <div className="modal-cookie">
              <div className="block-close">
                <img
                  onClick={() => setShowAcceptCookies(false)}
                  src={IconCloseModalPolicy}
                  className="close-icon"
                  alt="close-icon"
                  width={12}
                />
              </div>
              <div className="content-modal">
                เราใช้อีเมลเพื่อส่งการแจ้งเตือนของค่าอุณหภูมิและความชื้นที่ต่ำและสูงกว่ากำหนดเท่านั้น
              </div>
              <br />
              <div className="link-policy">
                <a href={PATHS.policy()}>
                  อ่านเพิ่มเติมคลิก (Privacy Policy) และ (Cookie Policy)
                </a>
              </div>
              <div
                className="button-accept"
                onClick={() => {
                  setShowAcceptCookies(false);
                  sessionStorage.setItem("accept", "true");
                  document.cookie = "accepted=true; path=/";
                }}
              >
                รับทราบ
              </div>
            </div>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LayoutDefault;
